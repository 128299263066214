import React, { createContext, ReactNode, ReducerState, useMemo, useReducer } from "react";
import { ToastBar, Toaster } from "react-hot-toast";

export const initialState = {};

interface ReducerActionType {
  type: string;
}

const Reducer = (state: ReducerState<any>, action: ReducerActionType) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const GlobalContext = createContext(initialState);

function GlobalProvider({
  children,
  defaultInitialState = {},
}: {
  children: ReactNode;
  defaultInitialState?: Record<string, any>;
}) {
  const initState = { ...initialState, ...defaultInitialState };
  const [state] = useReducer(Reducer, initState);

  const contextValue = useMemo(
    () => ({
      globalState: state,
    }),
    [state],
  );

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}

      <Toaster toastOptions={{ duration: 5000 }}>
        {(t) => (
          <ToastBar
            toast={t}
            style={{
              ...t.style,
              animation: t.visible ? "custom-enter 1s ease" : "custom-exit 1s ease",
            }}
          />
        )}
      </Toaster>
    </GlobalContext.Provider>
  );
}

export default GlobalProvider;
