import React, { createContext, useMemo } from "react";

import { LoadingSpinner } from "../assets/svg";
import { useMyMemberships } from "../external-api/profile";
import { checkIsCustomerAdmin } from "../permissions";
import { ThemeColors } from "../themes";

export const UserMembershipContext = createContext({ isCustomerAdmin: false });

function UserMembershipProvider({ children }: { children: React.ReactNode }) {
  const { data: membershipData, isLoading } = useMyMemberships();

  const isCustomerAdmin = useMemo(() => checkIsCustomerAdmin(membershipData), [membershipData]);

  const contextValue = useMemo(
    () => ({
      isCustomerAdmin: Boolean(isCustomerAdmin),
    }),
    [isCustomerAdmin],
  );

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <LoadingSpinner color={ThemeColors.primary[400]} width="50px" height="50px" />
      </div>
    );
  }
  return <UserMembershipContext.Provider value={contextValue}>{children}</UserMembershipContext.Provider>;
}

export default UserMembershipProvider;
