import React, { useCallback } from "react";

import { Megaphone } from "~/assets/svg";
import { ThemeColors } from "~/themes";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../../Tooltip";

const SuggestionButton = () => {
  const buttonClickHandler = useCallback(() => {
    window.open("https://istari.canny.io", "_blank");
  }, []);

  return (
    <Tooltip>
      <TooltipTrigger aria-label="Help and Suggestions" onClick={buttonClickHandler}>
        <Megaphone data-testid="suggestionIcon" width="22px" height="22px" color={ThemeColors.gray[400]} />
      </TooltipTrigger>
      <TooltipContent>Help and Suggestions</TooltipContent>
    </Tooltip>
  );
};

export default SuggestionButton;
