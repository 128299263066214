import React from "react";

import { Tooltip, TooltipContent, TooltipTrigger } from "../Tooltip";

type IconButtonVariant = "solid" | "transparent";

type ButtonProps = {
  dataTestId?: string;
  disabled?: boolean;
  label: string;
  icon: React.ReactNode;
  variant?: IconButtonVariant;
  onClick: () => void;
};

const IconButton = ({ dataTestId, disabled = false, label, icon, variant = "solid", onClick }: ButtonProps) => {
  const buttonStyle = "flex items-center justify-center rounded border-none cursor-pointer";

  const variantStyles = {
    solid: "w-34px h-34px color-gray-800 bg-gray-100 hover:bg-gray-50",
    transparent: "w-34px h-34px p-0 rounded-full color-gray-800 bg-transparent hover:bg-[#f7f8f980]",
  };

  return (
    <Tooltip>
      <TooltipTrigger
        aria-label={label}
        className={`${buttonStyle} ${variantStyles[variant]}`}
        data-testid={dataTestId}
        disabled={disabled}
        onClick={onClick}
      >
        {icon}
      </TooltipTrigger>
      <TooltipContent>{label}</TooltipContent>
    </Tooltip>
  );
};

export default IconButton;
