import { LazyArtifact, LazyModel, LazyModelPage, LazyWasmRevision } from "@istari/istari-client";
import { useMutation, useQuery } from "@tanstack/react-query";
import { saveAs } from "file-saver";

import { WasmProperties } from "@istari/istari-wasm";
import { AccessRelationship, PatchOp } from "@istari/istari-client/dist/src/openapi";
import { sortArrayByKeyAndOrder } from "~/utils/array";
import showToast from "../components/Toast";
import { HttpStatusCodeType } from "../enums";
import clientSDK from "./clientSDK";

// FS2
export interface Model {
  artifacts: [];
  file: {
    revisions: LazyWasmRevision[];
    id: string;
    version?: string | undefined;
  };
  id: string;
}

export type ModelProperties = {
  name: string | undefined;
  extension: string | undefined;
};

export interface ModelData {
  model: {
    artifacts: LazyArtifact[];
    file: {
      revisions: LazyWasmRevision[];
      id: string;
      version?: string | undefined;
    };
    id: string;
  };
  modelContent?:
    | {
        bytes: number[] | undefined; // ArrayBuffer;
      }
    | undefined;
  modelProperties: {
    description: string | undefined;
    extension: string | undefined;
    mime: string | undefined;
    name: string;
    size: number;
    version_name: string | undefined;
  };
}

export type ModelArtifact = LazyArtifact & {
  artifact_extension: string;
  id: string;
  name: string;
};

// end FS2

export interface ListOptions {
  size: number;
  page: number;
  order_by: string | undefined;
  model_type__not_in?: string;
  name__like?: string;
  processing_status__not_in?: string;
}

export interface ListOptionsExtended extends ListOptions {
  creator__sub__not_in?: string;
  creator__sub?: string;
  // name__like?: string;
  model_type?: string;
}

interface RenameModelPayload {
  modelId: string;
  name: string;
}

export const modelQueryKeys = {
  all: [],
  item: (id: string) => [...modelQueryKeys.all, id],
  list: (filter: ListOptions) => [...modelQueryKeys.all, "list", filter],
  tasks: (modelId: string) => [...modelQueryKeys.item(modelId), "tasks"],
};

export const artifactQueryKeys = {
  all: ["artifacts"],
  item: (id: string) => [...artifactQueryKeys.all, id],
};

export interface ModelProps {
  id: string;
  created: string;
  description: string;
  extension: string;
  external_identifier: string | undefined;
  mime: string | undefined;
  name: string;
  size: number;
  version_name: string | undefined;
}

export interface ModelType extends LazyModel, WasmProperties {
  created: string;
  modelAccessList: AccessRelationship[];
}

export const useModels = ({ filter }: { filter: ListOptions }) =>
  useQuery(modelQueryKeys.list(filter), () =>
    clientSDK
      .listModelsLazy(filter.page, filter.size, filter.order_by)
      .then(async (data: LazyModelPage) => {
        const list = data.items.map(async (model: LazyModel) => {
          const modelPropsData: ModelType[] = [];
          const modelAccessList = await clientSDK.access.listModelAccess({ modelId: model.id });
          const properties = await model.readCurrentProperties();
          modelPropsData.push({
            ...model,
            ...properties,
            id: model.id,
            created: model.file.revisions[0].created,
            modelAccessList,
          });
          return modelPropsData;
        });

        const result = await Promise.all(list).then((response) => response.flat());

        return {
          items: result,
          total: data.total,
        };
      })
      .catch((error: Error) => {
        console.error("Error loading models", error);
        showToast("An error occurred while loading the models", "error");
        return {
          items: [],
          total: 0,
        };
      }),
  );

export const useModel = (modelId: string) =>
  useQuery(modelQueryKeys.item(modelId), () =>
    clientSDK
      .getModelLazy(modelId)
      .then(async (model: LazyModel) => {
        const modelProperties = await model.readCurrentProperties();
        const modelAccessList = await clientSDK.access.listModelAccess({ modelId: model.id });

        const revs = model.file.revisions.map(async (rev: LazyWasmRevision) => {
          const revProp = await rev.readProperties();
          return { ...rev, ...revProp };
        });

        const modelFullRevisions = await Promise.all(revs);

        // if (modelTypes[modelProperties.extension?.toLowerCase() as keyof typeof modelTypes].previewable) { // modelContent needed for canDownload from S3 check
        const modelContent = await model.readCurrentContent();

        return {
          model,
          modelAccessList,
          modelContent,
          modelFullRevisions: sortArrayByKeyAndOrder(modelFullRevisions, "created", "desc"),
          modelProperties,
        };
      })
      .catch((error: Error) => {
        console.error("Error loading model", error);
        if (
          error.message.includes(String(HttpStatusCodeType.Forbidden)) ||
          error.message.includes(String(HttpStatusCodeType.NotFound))
        ) {
          showToast("This model is not found or you do not have permission to view this model.", "error");
        } else {
          showToast("An error occurred while loading the model", "error");
        }
        return null;
      }),
  );

interface UploadModelVersionPayloadType {
  modelId: string;
  file: File;
  description?: string;
  versionName?: string;
  externalIdentifier?: string;
}

export const useUploadModelVersion = () =>
  useMutation((data: UploadModelVersionPayloadType) =>
    clientSDK.updateModelLazy(data.modelId, data.file, data.description, undefined, data.externalIdentifier),
  );

export const downloadModel = (modelContentBytes: number[], name: string | undefined, extension: string | undefined) => {
  if (modelContentBytes && name && extension) {
    const blob = new Blob([new Uint8Array(modelContentBytes)]);
    saveAs(new Blob([blob], { type: extension }), `${name}.${extension}`);
    showToast(`${name}.${extension} has been downloaded successfully.`, "success");
  } else {
    showToast("An error occurred while downloading the file.", "error");
  }
};

type ShareModelAccessRelationship = {
  accessRelationship: AccessRelationship;
  patchOpType: PatchOp;
};

export interface ShareModelPayload {
  modelId: string;
  accessGrants: ShareModelAccessRelationship[];
  resourceType: "model" | "artifact";
}

export const useShareModel = () =>
  useMutation(async (data: ShareModelPayload) => {
    let promises;
    if (data.resourceType === "model") {
      promises = data.accessGrants.map((item) =>
        clientSDK.access.patchModelAccess({
          modelId: data.modelId,
          accessRelationship: item.accessRelationship,
          patchOp: item.patchOpType,
        }),
      );
    } else {
      promises = data.accessGrants.map((item) =>
        clientSDK.access.patchArtifactAccess({
          artifactId: data.modelId,
          accessRelationship: item.accessRelationship,
          patchOp: item.patchOpType,
        }),
      );
    }
    await Promise.all(promises);
  });

export const useRenameModel = () =>
  useMutation(
    (data: RenameModelPayload) =>
      clientSDK.updateModelLazy(data.modelId, new File(["ee"], "e"), undefined, undefined, undefined), // faking it
  );
// clientSDK.updateModelLazy(`/model/${data.modelId}`, { name: data.name }));

export const useExtractModel = (modelId: string) =>
  useMutation(
    () => clientSDK.updateModelLazy(modelId, new File(["ee"], "e"), undefined, undefined, undefined), // faking it
  );

export const useGetModelLatestExtractionTask = (modelId: string) =>
  // faking it
  useQuery(modelQueryKeys.tasks(modelId), () => clientSDK.getModelLazy(modelId), {
    refetchOnWindowFocus: false,
    retry: false,
  });

/*
export const useRemoveModel = () =>
  useMutation((data: RemoveModelPayload) => fileService.remove(`/model/${data.modelId}`));

export async function downloadArtifact(fileName: string, modelId: string, artifactId: string) {
  const refetchedModelArtifact = await fileService.get(`/model/${modelId}/artifact/${artifactId}`);
  downloadSaveS3SignedUrlFile(refetchedModelArtifact.signed_download_url, fileName);
}

export const useGetArtifactDataById = (modelId: string, artifactId: string) =>
  useQuery(artifactQueryKeys.item(artifactId), () => fileService.get(`/model/${modelId}/artifact/${artifactId}`), {
    refetchOnWindowFocus: false,
    enabled: !!modelId && !!artifactId,
  });
 */

export interface ArtifactType extends WasmProperties {
  created: string;
  artifactAccessList: AccessRelationship[];
}

export const useGetArtifactsByModelId = (modelId: string) =>
  useQuery(["artifacts", modelId], () =>
    clientSDK
      .listArtifactsLazy(modelId)
      .then(() => {
        // async (data: LazyArtifactPage)
        /* const list = data.items.map(async (artifact: LazyArtifact) => {
          const artifactPropsData: ArtifactType[] = [];
          
          const artifactAccessList = await clientSDK.access.listModelAccess({ modelId: artifact.id });
          const properties = await artifact.readCurrentProperties();
          artifactPropsData.push({
            ...artifact,
            ...properties,
            created: artifact.file.revisions[0].created,
            artifactAccessList,
          });

          return artifactPropsData;
        });

        const result = await Promise.all(list).then((response) => response.flat()); */
        const mockArtifactsData = [
          {
            name: "fake artifact 1",
            artifact_extension: "json",
            id: "1",
          },
          {
            name: "fake artifact 2",
            artifact_extension: "csv",
            id: "2",
          },
          {
            name: "fake artifact 3",
            artifact_extension: "png",
            id: "3",
          },
        ] as ModelArtifact[];

        return {
          items: mockArtifactsData,
          total: 3,
        };
      })
      .catch((error: Error) => {
        console.error("Error loading artifacts", error);
        showToast("An error occurred while loading the artifacts", "error");
        return {
          items: [],
          total: 0,
        };
      }),
  );
