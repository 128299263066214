import { AxiosError } from "axios";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useAuth } from "react-oidc-context";

import { queryClient } from "~/api/queryClient";
import { modelQueryKeys } from "~/api/useModels";
import { useUpload } from "~/api/useUpload";
import FilesStatusCard from "~/components/FilesStatusCard";
import FileStatusRow, { FileStatusRowProps } from "~/components/FileStatusRow/FileStatusRow";
import { AcceptableFileExtensions, FileUploadStatusType, HttpStatusCodeType } from "~/enums";
import { FilesStatusContext } from "~/contexts/FilesStatusContext";
import showToast from "~/components/Toast/Toast";

type FileStatusRowPropsExtended = FileStatusRowProps & {
  errorMsg: string;
  key: string;
  status: string;
  binary?: File;
};

function getErrorMessage(err: AxiosError) {
  if (err?.response?.status === HttpStatusCodeType.UnprocessableContent) {
    return `File type is not one of the supported types: ${Object.values(AcceptableFileExtensions).join(", ")}`;
  }
  const m = JSON.parse(
    err.message.substring(err.message.lastIndexOf("{"), err.message.indexOf("}") + 1).replaceAll("\\", ""),
  );
  return m.detail || "An error occurred while uploading the file.";
}

function FilesStatusFloatingCard() {
  const auth = useAuth();
  const filesCtx = useContext(FilesStatusContext);
  const uploadModel = useUpload();

  const uploadFileHandler = useCallback(
    async (file: FileStatusRowPropsExtended, id: string) => {
      // Update the original map (the map in the files context). Set the file's status to uploading
      filesCtx.updateFile(id, { status: FileUploadStatusType.Uploading, errorMsg: "" });
      let data;
      try {
        data = await uploadModel.mutateAsync(file?.binary as File);

        if (data) {
          filesCtx.updateFile(id, {
            status: FileUploadStatusType.Uploaded,
            errorMsg: "",
          });
          queryClient.invalidateQueries(modelQueryKeys.all);
        }
      } catch (err) {
        if (
          (err as AxiosError).message.includes(String(HttpStatusCodeType.Forbidden || HttpStatusCodeType.Unauthorized))
        ) {
          showToast("Your session has expired. Please refresh the page or log in again.", "error");
        }

        filesCtx.updateFile(id, {
          status: FileUploadStatusType.UploadingError,
          errorMsg: getErrorMessage(err as AxiosError),
        });
      }
    },
    [filesCtx],
  );

  const renderFilesRows = useMemo(() => {
    const arrayOfFiles = Array.from(filesCtx.files);

    return arrayOfFiles?.map(([id, file]) => (
      <FileStatusRow key={id} fileName={file?.binary?.name} errorMsg={file?.errorMsg} status={file?.status} />
    ));
  }, [filesCtx.files]);

  useEffect(() => {
    // Get the newly added files
    const newlyAddedFiles = new Map(
      Array.from(filesCtx.files).filter(([, file]) => file?.status === FileUploadStatusType.New),
    );

    // Upload the new files
    newlyAddedFiles.forEach((file, id) => {
      uploadFileHandler(file, id);
    });
  }, [filesCtx.files, uploadFileHandler]);

  if (!auth.isAuthenticated) {
    return null;
  }
  return (
    <FilesStatusCard
      show={filesCtx.showStatusCard}
      totalFilesCount={filesCtx.files?.size}
      onDismiss={() => filesCtx.setShowStatusCard(false)}
    >
      {renderFilesRows}
    </FilesStatusCard>
  );
}

export default FilesStatusFloatingCard;
