import React, { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";

import { IstariLogoColor } from "../../../assets/svg";
import { RoutePathType } from "../../../enums";
import { getEnv } from "../../../utils/get_env";
import FilesStatusButton from "./partials/FilesStatusButton";
import SuggestionButton from "./partials/SuggestionButton";
import UserMenu from "./partials/UserMenu";

interface HeaderProps {
  currentPageTitle?: string;
  fullPageLayout?: boolean;
  fullSize?: boolean;
  withLogo?: boolean;
}

const Header = ({ currentPageTitle, fullPageLayout, fullSize, withLogo }: HeaderProps) => {
  const getWidthAndLeftOfset = useMemo(() => {
    if (fullPageLayout) {
      return "w-full left-0 pl-20px";
    }
    if (fullSize) {
      return `w-[calc(100% - 210px)] ml-210px`;
    }
    return `w-[calc(100% - 60px)] ml-60px`;
  }, [fullPageLayout, fullSize]);

  return (
    <header
      data-testid="page-header"
      className={`bg-white-0 border border-solid border-gray-100 z-100 fixed max-w-full bg-white left-0 right-0 ${getWidthAndLeftOfset} ${
        getEnv("VITE_ITAR") === "true" ? "top-33px" : "top-0"
      }`}
    >
      <div className="flex min-h-auto">
        <div className="flex flex-wrap w-full h-15 items-center justify-between">
          {(withLogo || currentPageTitle) && (
            <div className={`flex basis-1/3 grow-0 max-w-lg ${fullSize ? "ml-25px" : ""}`}>
              {withLogo && (
                <RouterLink aria-label="Istari Digital Home" to={RoutePathType.Root}>
                  <IstariLogoColor data-testid="logo-icon" title="Istari Digital" />
                </RouterLink>
              )}

              {currentPageTitle && (
                <span
                  aria-level={1}
                  role="heading"
                  data-testid="header-page-title"
                  className="ml-6 subtitle2b color-gray-800"
                >
                  {currentPageTitle}
                </span>
              )}
            </div>
          )}

          <div className="mr-4 basis-1/4 grow-0 max-w-lg flex flex-row items-center justify-right">
            <div className="flex items-baseline justify-center w-20 space-x-4">
              <SuggestionButton />
              <FilesStatusButton />
            </div>
            <span className="block mx-5 w-px w-1 h-10 bg-gray-100" />
            <UserMenu />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
