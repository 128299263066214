const LightModeThemeColors = {
  primary: {
    950: "#040B16",
    900: "#112A59",
    800: "#343423",
    700: "#183A7A",
    600: "#1E4A9B",
    500: "#255ABD",
    400: "#2B6ADE",
    300: "#608FE6",
    200: "#95B5EF",
    100: "#D5E1F8",
    50: "#F0F5FD",
  },
  secondary: {
    950: "#031310",
    900: "#082F28",
    800: "#0C4C40",
    700: "#116859",
    600: "#168471",
    500: "#1AA189",
    400: "#1FBDA1",
    300: "#57CDB9",
    200: "#8FDED0",
    100: "#D2F2EC",
    50: "#EFFAF8",
  },
  gray: {
    950: "#191B23",
    900: "#2E3038",
    800: "#44464F",
    700: "#5C5E67",
    600: "#757780",
    500: "#8F9099",
    400: "#A9ABB4",
    300: "#C5C6D0",
    200: "#E1E2EC",
    100: "#F0F0FA",
    50: "#F8F8FD",
    30: "#D7DAE1",
    20: "#F7F8F9",
  },
  blue: {
    600: "#0A2630",
    500: "#1D7291",
    400: "#31BEF2",
    300: "#64CEF5",
    200: "#98DFF9",
    100: "#CBEFFC",
    50: "#CBEFFC",
  },
  green: {
    600: "#091A0D",
    500: "#1A4D28",
    400: "#2B8043",
    300: "#60A072",
    200: "#95BFA1",
    100: "#CADFD0",
  },
  yellow: {
    600: "#31260A",
    500: "#94721D",
    400: "#F6BE31",
    300: "#F8CE64",
    200: "#FBDF98",
    100: "#FDEFCB",
  },
  red: {
    600: "#260002",
    500: "#730005",
    400: "#C00008",
    300: "#D04046",
    200: "#DF8083",
    100: "#FBDCDE",
  },
  violet: {
    600: "#1D172F",
    500: "#58458E",
    400: "#9273ED",
    300: "#AD96F1",
    200: "#C8B9F6",
    100: "#E4DCFB",
  },
  white: {
    0: "#FFFFFF",
    50: "#ECECEC",
    100: "#E2E2E2",
  },
};

export default LightModeThemeColors;
