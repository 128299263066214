import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";
import * as React from "react";

import { Close } from "../../assets/svg";
import Button from "../Button";
import type { ButtonVariant } from "../Button/Button";
import IconButton from "../IconButton";

interface DialogOptions {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

function useDialog({ open, onOpenChange }: DialogOptions) {
  const [labelId, setLabelId] = React.useState<string | undefined>();
  const [descriptionId, setDescriptionId] = React.useState<string | undefined>();
  const data = useFloating({
    open,
    onOpenChange(_nextOpen, _event, reason) {
      // Other ones include 'reference-press' and 'ancestor-scroll'
      // if enabled.
      if (reason === "escape-key" || reason === "outside-press") {
        onOpenChange(false);
      }
    },
  });

  const { context } = data;

  const click = useClick(context, {
    enabled: true,
  });
  const dismiss = useDismiss(context, { escapeKey: true, outsidePressEvent: "mousedown" });
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      onOpenChange,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [open, onOpenChange, interactions, data, labelId, descriptionId],
  );
}

type ContextType =
  | (ReturnType<typeof useDialog> & {
      setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>;
      setDescriptionId: React.Dispatch<React.SetStateAction<string | undefined>>;
    })
  | null;

const DialogContext = React.createContext<ContextType>(null);

const useDialogContext = () => {
  const context = React.useContext(DialogContext);

  if (context == null) {
    throw new Error("Dialog components must be wrapped in <Dialog />");
  }

  return context;
};

function Dialog({
  children,
  ...options
}: {
  children: React.ReactNode;
} & DialogOptions) {
  const dialog = useDialog(options);
  return <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>;
}

const DialogContainer = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>((props, propRef) => {
  const { context: floatingContext, ...context } = useDialogContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!floatingContext.open) return null;
  return (
    <FloatingPortal>
      <FloatingOverlay className="grid place-items-center z-1000 bg-[#000000b3]" lockScroll>
        <FloatingFocusManager context={floatingContext} visuallyHiddenDismiss>
          <div
            ref={ref}
            aria-labelledby={context.labelId}
            aria-describedby={context.descriptionId}
            {...context.getFloatingProps(props)}
          >
            {props.children}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
});

const DialogHeading = ({ title, onHide }: { title: string; onHide: () => void }) => {
  const { setLabelId } = useDialogContext();
  const id = useId();

  // Only sets `aria-labelledby` on the Dialog root element
  // if this component is mounted inside it.
  React.useLayoutEffect(() => {
    setLabelId(id);
    return () => setLabelId(undefined);
  }, [id, setLabelId]);

  return (
    <div className="flex items-center justify-between p-4 border border-b-solid border-gray-200">
      <span className="subtitle2b" id={id}>
        {title}
      </span>
      <IconButton
        dataTestId="close-modal-btn"
        icon={<Close />}
        label="Close modal"
        variant="transparent"
        onClick={onHide}
      />
    </div>
  );
};

const DialogContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  ({ children, ...props }, ref) => {
    const { setDescriptionId } = useDialogContext();
    const id = useId();

    // Only sets `aria-describedby` on the Dialog root element
    // if this component is mounted inside it.
    React.useLayoutEffect(() => {
      setDescriptionId(id);
      return () => setDescriptionId(undefined);
    }, [id, setDescriptionId]);

    return (
      <div className="p-4 subtitle3" {...props} ref={ref} id={id}>
        {children}
      </div>
    );
  },
);

type ModalActionButton = {
  dataTestId: string;
  disabled: boolean;
  label: string;
  loading: boolean;
  variant: ButtonVariant;
  clickHandler: () => void;
};

const DialogActions = ({
  actionButton,
  includeCancelButton,
  onHide,
}: {
  actionButton: ModalActionButton;
  includeCancelButton?: boolean;
  onHide: () => void;
}) => (
  <div className="p-4 flex gap-x-2 items-center justify-center border border-t-solid border-gray-200">
    {includeCancelButton && (
      <Button dataTestId="modal-cancel-button" label="Cancel" fullWidth variant="ternary" onClick={onHide} />
    )}
    <Button
      dataTestId={actionButton.dataTestId}
      disabled={actionButton.disabled || actionButton.loading}
      fullWidth
      label={actionButton.label}
      variant={actionButton.variant}
      onClick={actionButton.clickHandler}
    />
  </div>
);

const Modal = ({
  actionButton,
  content,
  includeCancelButton,
  open,
  title,
  onHide,
  onOpenChange,
}: {
  actionButton: ModalActionButton;
  content: React.ReactNode;
  includeCancelButton: boolean;
  open: boolean;
  title: string;
  onHide: () => void;
  onOpenChange: (open: boolean) => void;
}) => (
  <Dialog open={open} onOpenChange={onOpenChange!}>
    <DialogContainer className="w-600px bg-white-0">
      <DialogHeading onHide={onHide} title={title} />
      <DialogContent>{content}</DialogContent>
      <DialogActions includeCancelButton={includeCancelButton} onHide={onHide} actionButton={actionButton} />
    </DialogContainer>
  </Dialog>
);

export default Modal;
