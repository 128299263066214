import React from "react";

import errorScream from "../../assets/images/error_scream.jpg";
import { IstariLogoColorLarge } from "../../assets/svg";

function ErrorBoundaryDisplay() {
  return (
    <div className="bg-[#f3f3f3] w-full h-screen flex justify-center items-center text-center">
      <div className="gap-5 items-center mt-4">
        <IstariLogoColorLarge />
        <div className="p-30px gap-5 items-center text-center">
          <img alt="Istari error" src={errorScream} width="440px" height="auto" />
          <div className="mt-30px h4 color-red-400">Oh no! An error has occurred.</div>

          <div className="mt-20px subtitle2 color-gray-600 text-center">
            Looks like we’ve encountered an issue. It has been reported to our team and we will get around to fixing it
            soon.
          </div>
        </div>

        {/* IstariLink href or to does not work with route when error occurs page is shown */}
        <a href="/">
          <span className="subtitle2 color-gray-600 text-center">Go back to Home Page</span>
        </a>
      </div>
    </div>
  );
}

export default ErrorBoundaryDisplay;
