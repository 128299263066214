import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { LoadingSpinner } from "../../../assets/svg";
import { getEnv } from "../../../utils/get_env";
import TopBanner from "../../TopBanner";

import Header from "../Header";

export const FullPageWidthLayout = () => (
  <>
    {getEnv("VITE_ITAR") === "true" && <TopBanner />}
    <Header fullPageLayout withLogo />

    <main className={`pt-${getEnv("VITE_ITAR") === "true" ? 90 : 60}-px`}>
      <Suspense fallback={<LoadingSpinner />}>
        <Outlet />
      </Suspense>
    </main>
  </>
);

export default FullPageWidthLayout;
