import Colors from "./LightModeThemeColors";

export const ThemeColors = Colors;
export const Theme = {
  boxShadow: {
    lg: "-3px 16px 25px rgba(27, 31, 35, 0.14),1px 2px 16px rgba(27, 31, 35, 0.06)",
    sm: "2px 3px 22px rgba(27, 31, 35, -0.86),1px 2px 8px rgba(27, 31, 35, 0.09)",
  },
  fontSize: {
    sm: "0.75rem",
    base: "0.875rem",
    md: "1rem",
    lg: "1.35rem",
  },
  fontWeight: {
    bold: 600,
  },
  spacing: {
    xs: "4px",
    sm: "8px",
    md: "12px",
    lg: "16px",
    xl: "24px",
  },
  colors: ThemeColors,
  breakpoints: {
    sm: "480px",
    md: "768px",
    lg: "1000px",
  },
};
