import React, { useMemo, useState } from "react";

import { CaretDown, CaretUp, Close } from "../../assets/svg";
import IconButton from "../IconButton";

interface FilesStatusCardProps {
  children: React.ReactNode;
  show: boolean;
  totalFilesCount: number;
  onDismiss: () => void;
}

const FilesStatusCard = ({ children, show, totalFilesCount, onDismiss }: FilesStatusCardProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const collapsedContainerHeight = useMemo(() => (isCollapsed ? "h-54px" : "h-auto"), [isCollapsed]);

  return (
    <div className={show ? "visible" : "hidden"} data-testid="file-upload-status-card-container">
      <div
        className={`w-540px rounded-lg bg-white-0 shadow-lg px-5px fixed bottom-20px right-20px z-9999 overflow-y-hidden ${collapsedContainerHeight}`}
      >
        <div className="flex justify-between items-center p-12px">
          <div className="flex items-center gap-x-8px">
            <h6 className="m-none p-none color-gray-800">Files Uploading & Processing ({totalFilesCount})</h6>
          </div>

          <div className="flex gap-x-16px">
            <IconButton
              dataTestId="toggle-file-upload-status-card-button"
              label={isCollapsed ? "Expand" : "Collapse"}
              icon={isCollapsed ? <CaretUp /> : <CaretDown />}
              variant="transparent"
              onClick={() => setIsCollapsed((prevState) => !prevState)}
            />

            <IconButton
              dataTestId="close-file-upload-status-card-button"
              label="Close"
              icon={<Close />}
              variant="transparent"
              onClick={onDismiss}
            />
          </div>
        </div>

        {/* ---------- Files status rows ---------- */}
        <div className="max-h-320px overflow-y-auto pb-10px">{children}</div>
      </div>
    </div>
  );
};

export default FilesStatusCard;
