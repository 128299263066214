export function sortArrayByKeyAndOrder<T>(array: T[], key: keyof T, order: "asc" | "desc"): T[] {
  return array.sort((a, b) => {
    const aValue = String(a[key]).toLowerCase();
    const bValue = String(b[key]).toLowerCase();
    if (order === "asc") {
      return aValue.localeCompare(bValue);
    }
    return bValue.localeCompare(aValue);
  });
}
