import React, { useCallback, useContext } from "react";

import { DiamondIcon } from "~/assets/svg";
import { FilesStatusContext } from "~/contexts/FilesStatusContext";
import { Tooltip, TooltipContent, TooltipTrigger } from "~/components/Tooltip";

const FilesStatusButton = () => {
  const filesCtx = useContext(FilesStatusContext);

  const buttonClickHandler = useCallback(() => {
    filesCtx.setShowStatusCard(!filesCtx.showStatusCard);
  }, [filesCtx]);

  return (
    <Tooltip>
      <TooltipTrigger
        aria-label="Files upload status"
        onClick={buttonClickHandler}
        disabled={!filesCtx.files?.size}
        className="flex items-center justify-center cursor-pointer rounded bg-white-0 border-0"
      >
        <DiamondIcon width="21" height="21" />
        {/* Status count chip */}
        {!!filesCtx.files?.size && (
          <span className="rounded-full w-20px h-20px items-center justify-center bg-gray-400 relative flex right-4px -top-7px">
            <span className="color-white-0 text-sm">{filesCtx.files?.size}</span>
          </span>
        )}
      </TooltipTrigger>
      <TooltipContent>File upload status</TooltipContent>
    </Tooltip>
  );
};

export default FilesStatusButton;
