import {
  autoUpdate,
  FloatingFocusManager,
  FloatingList,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListItem,
  useListNavigation,
  useRole,
} from "@floating-ui/react";
import React, {
  createContext,
  ElementType,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import { MoreVertical } from "../../assets/svg";
import { ListItem } from "../../types/option";

interface SelectContextValue {
  selectedIndex: number | null | undefined;
  getItemProps: ReturnType<typeof useInteractions>["getItemProps"];
  handleSelect: (menuItemIndex: number | null) => void;
}

const SelectContext = createContext<SelectContextValue>({} as SelectContextValue);

function Select({
  ariaLabel,
  children,
  triggerLabelIcon,
  dataTestId,
  placement,
}: {
  ariaLabel: string;
  children: ReactNode;
  triggerLabelIcon: ReactElement;
  dataTestId?: string;
  placement?: "bottom-start" | "bottom-end";
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const { refs, floatingStyles, context } = useFloating({
    placement: placement || "bottom-end",
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    // middleware: [autoPlacement()],
  });

  const elementsRef = useRef<Array<HTMLElement | null>>([]);
  const labelsRef = useRef<Array<string | null>>([]);

  const handleSelect = React.useCallback((index: number | null) => {
    setSelectedIndex(index);
    setIsOpen(false);
  }, []);

  const listNav = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "listbox" });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([listNav, click, dismiss, role]);

  const selectContext = useMemo(
    () => ({
      activeIndex,
      selectedIndex,
      getItemProps,
      handleSelect,
    }),
    [activeIndex, selectedIndex, getItemProps, handleSelect],
  );

  return (
    <>
      <div
        aria-label={ariaLabel}
        data-testid={dataTestId}
        role="button"
        className="color-gray-700 subtitle2 cursor-pointer flex items-center"
        ref={refs.setReference}
        tabIndex={0}
        {...getReferenceProps()}
      >
        {triggerLabelIcon}
      </div>
      <SelectContext.Provider value={selectContext}>
        {isOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              className="min-w-150px mt-2 bg-white-0 border border-gray-200 rounded-md shadow-sm z-10"
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                {children}
              </FloatingList>
            </div>
          </FloatingFocusManager>
        )}
      </SelectContext.Provider>
    </>
  );
}

function Option({
  icon,
  label,
  value,
  selectedIndex,
  onItemClick,
}: {
  icon: ElementType | ReactNode;
  label: string;
  value: string;
  selectedIndex: number | null;
  onItemClick: (itemValue: string) => void;
}) {
  const { getItemProps, handleSelect } = useContext(SelectContext);

  const { index, ref } = useListItem({ label });

  const isSelected = selectedIndex === index;

  return (
    <button
      className={`flex items-center gap-5px subtitle3 color-gray-700 cursor-pointer border-0 block w-full text-left px-3 py-2 capitalize hover:bg-gray-100 ${isSelected ? "bg--[#2b6ade14" : "bg-white-0"}`}
      ref={ref}
      role="option"
      type="button"
      aria-selected={isSelected}
      {...getItemProps({
        onClick: () => {
          onItemClick(value);
          handleSelect(index);
        },
      })}
    >
      {icon && (icon as ReactNode)}
      {label}
    </button>
  );
}

export default function Menu({
  ariaLabel,
  dataTestId,
  options,
  selectedItemIndex,
  placement = "bottom-end",
  triggerLabelIcon = <MoreVertical width="18px" height="18px" />,
  onMenuItemClick,
}: {
  ariaLabel: string;
  dataTestId?: string;
  options: ListItem[];
  selectedItemIndex?: number | null | undefined;
  placement?: "bottom-start" | "bottom-end";
  triggerLabelIcon?: ReactElement;
  onMenuItemClick: (itemValue: string) => void;
}) {
  return (
    <Select ariaLabel={ariaLabel} dataTestId={dataTestId} placement={placement} triggerLabelIcon={triggerLabelIcon}>
      {options.map((option) => (
        <Option
          key={option.value}
          icon={option.icon}
          label={option.label}
          value={option.value}
          selectedIndex={selectedItemIndex!}
          onItemClick={onMenuItemClick}
        />
      ))}
    </Select>
  );
}
