import React, { Suspense, useContext, useEffect, useRef, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { DiamondIcon, DocsIcon, Home, LoadingSpinner, Settings, Sliders } from "../../../assets/svg";
import { RoutePathType, SettingsSubpage } from "../../../enums";
import { UserMembershipContext } from "../../../contexts/UserMembershipContext";
import { useResponsiveWidth } from "../../../hooks/useResponsiveWidth";
import { Theme, ThemeColors } from "../../../themes";
import { NavItem, SideBarProps } from "../../../types/option";
import { getEnv } from "../../../utils/get_env";
import TopBanner from "../../TopBanner";
import Header from "../Header";
import SideBar from "../SideBar";

interface LayoutWithSideBarProps extends Pick<SideBarProps, "activeNavItem" | "navLists"> {
  children: React.ReactNode | React.ReactNode[];
  currentPageTitle?: string;
}

export const LayoutWithSideBar = ({ activeNavItem, children, currentPageTitle, navLists }: LayoutWithSideBarProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const widthRef = useResponsiveWidth(ref);
  const largeScreen =
    widthRef && widthRef > Number(Theme.breakpoints.lg.substring(0, Theme.breakpoints.lg.indexOf("px")));

  return (
    <div ref={ref}>
      <Header currentPageTitle={currentPageTitle} fullSize={Boolean(largeScreen)} />
      <main className="max-w-full pb-50px">
        {getEnv("VITE_ITAR") === "true" && <TopBanner />}

        <div className={`${getEnv("VITE_ITAR") === "true" ? "pt-33px" : ""}`}>
          <SideBar navLists={navLists} activeNavItem={activeNavItem} fullSize={Boolean(largeScreen)} />

          <div
            className={`${largeScreen ? `ml-210px w-[calc(100% - 210px)]` : `ml-60px w-[calc(100% - 60px)]`} box-border flex justify-center px-50px mt-90px`}
          >
            {children}
          </div>
        </div>
      </main>
    </div>
  );
};

function LeftSidebarLayout() {
  const [activeNavItem, setActiveNavItem] = useState("");
  const location = useLocation();

  const { isCustomerAdmin } = useContext(UserMembershipContext);

  const navigators = useMemo(() => {
    const arrayOfLists = [
      // ----- FIRST LIST
      {
        home: {
          label: "Home",
          value: RoutePathType.Upload,
          icon: <Home />,
        },
        allModels: {
          label: "All Models",
          value: RoutePathType.AllModels,
          icon: <DiamondIcon width="24px" height="24px" />,
        },
      },

      // ----- SECOND LIST
      {
        documentation: {
          label: "Documentation",
          value: RoutePathType.Documentation,
          icon: <DocsIcon />,
          children: [
            {
              label: "Welcome to Istari",
              value: `${getEnv("VITE_DOCUMENTATION_URL")}/docs/get-started/Welcome-to-Istari`,
            },
            {
              label: "Core Features",
              value: `${getEnv("VITE_DOCUMENTATION_URL")}/docs/get-started/Core-Features`,
            },
            {
              label: "Magic Doc Tutorial",
              value: `${getEnv("VITE_DOCUMENTATION_URL")}/docs/get-started/Magic-Doc-Tutorial`,
            },
          ],
        },
        settings: {
          label: "Settings",
          value: RoutePathType.Settings,
          icon: <Settings />,
          children: [
            {
              label: "Profile Settings",
              value: SettingsSubpage.PROFILE_SETTINGS,
            },
            {
              label: "Change Password",
              value: SettingsSubpage.CHANGE_PASSWORD,
            },
            /* {
              label: "Developer Settings",
              value: SettingsSubpage.DEVELOPER_SETTINGS,
            }, */
          ],
        },
        /* magicDocs: {
          label: "Magic Docs",
          value: RoutePathType.MagicDocs,
          icon: <MagicDocFile />,
        }, */
      },
    ];

    const defaultSecondNavList = arrayOfLists[1];
    const { documentation, settings } = defaultSecondNavList; // magicDocs,

    const adminPanel = {
      label: "Admin Panel",
      value: RoutePathType.AdminPanel,
      icon: <Sliders />,
    };
    const lowerSetOfNavs = isCustomerAdmin
      ? {
          documentation,
          adminPanel,
          settings,
          // magicDocs,
        }
      : {
          documentation,
          settings,
          // magicDocs,
        };

    const arrayOfNavs = [arrayOfLists[0], lowerSetOfNavs];

    const navItems = Object.values(arrayOfNavs.reduce((obj, item) => Object.assign(obj, { ...item }), {})) as NavItem[];

    const navItemsValues = Object.values(
      // Convert the navigators array of objects to a single object
      arrayOfNavs.reduce((obj, item) => Object.assign(obj, { ...item }), {}),
    )?.map((item) => (item as NavItem).value);

    // convert the array of lists objects to array of arrays of objects values
    const arrayOfNavLists = arrayOfNavs.map((list) => Object.values(list)) as NavItem[][];

    return {
      navItems,
      navItemsValues,
      arrayOfNavLists,
    };
  }, [isCustomerAdmin]);

  useEffect(() => {
    const currPathname = location.pathname.replace("/", "");

    if (navigators.navItemsValues?.includes(currPathname)) {
      setActiveNavItem(currPathname);
    }
  }, [location, navigators]);

  return (
    <LayoutWithSideBar
      navLists={navigators.arrayOfNavLists}
      activeNavItem={activeNavItem}
      currentPageTitle={navigators.navItems?.find((item) => item.value === activeNavItem)?.label}
    >
      <Suspense fallback={<LoadingSpinner color={ThemeColors.primary[400]} width="40px" height="40px" />}>
        <Outlet />
      </Suspense>
    </LayoutWithSideBar>
  );
}

export default LeftSidebarLayout;
