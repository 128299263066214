import showToast from "../components/Toast";

function onCopySuccess(infoMessage: string, callback: any, displayToast: boolean) {
  if (!displayToast) return;
  if (callback) {
    callback(infoMessage);
  } else {
    showToast(infoMessage, "success");
  }
}

export const copyToClipboard = (
  stringToCopy: string,
  infoMessage: string,
  callback?: any,
  displayToast: boolean = true,
) => navigator.clipboard.writeText(stringToCopy).then(() => onCopySuccess(infoMessage, callback, displayToast));

export const generateUniqueId = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  // return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};
