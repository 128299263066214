import { IstariClient } from "@istari/istari-client";

import AuthenticationStore from "../auth";
import { getEnv } from "../utils/get_env";

const AS = AuthenticationStore();

const clientSDK = new IstariClient(
  getEnv("VITE_FILE_AUTH_ENDPOINT") || "http://localhost:8000",
  AS.getAccessToken() || "",
);

export default clientSDK;
