export enum RoutePathType {
  AdminPanel = "admin-panel",
  AllModels = "all-models",
  Documentation = "documentation",
  File = "file",
  MagicDocs = "magic-docs",
  NotFound = "404",
  Root = "/",
  Settings = "settings",
  SignIn = "sign-in",
  Upload = "upload",
}
