import { useEffect, useState } from "react";

import { THROTTLE_MS } from "../constants";
import { throttle } from "../utils/throttle";

export function useResponsiveWidth(
  ref: React.MutableRefObject<HTMLElement | null>,
  callback?: (width: number) => void,
) {
  const [width, setWidth] = useState<number>();

  useEffect(() => {
    const element = ref.current;

    // Update width using a resize observer.  Throttle the resizing callback.
    const resize = throttle((entries: ResizeObserverEntry[]) => {
      if (!entries || entries.length === 0) {
        return;
      }
      const updatedWidth = entries[0].contentRect.width;
      setWidth(updatedWidth);
      if (callback) {
        callback(updatedWidth);
      }
    }, THROTTLE_MS);

    if ("ResizeObserver" in window && element) {
      const resizeObserver = new ResizeObserver(resize);
      resizeObserver.observe(element);
      return () => {
        resizeObserver.unobserve(element);
      };
    }
    return () => {};
  }, [ref, callback]);

  return width;
}
