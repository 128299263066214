import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import "virtual:uno.css";

import { queryClient } from "./api/queryClient";
import { router } from "./routing/routes";
import { getEnv } from "./utils/get_env";

// zitadel user
const userStr = window.localStorage.getItem("istari-user");
const user = userStr ? JSON.parse(userStr) : null;
const userId = user?.profile?.sub;
const userEmail = user?.profile?.email;

// do not track if sentry flag is off and unlogged in flow
if (getEnv("VITE_SENTRY_ENABLED") === "true" && userId && userEmail) {
  Sentry.init({
    dsn: getEnv("VITE_SENTRY_DSN"),
    environment: `${getEnv("VITE_NODE_ENV")}`,
    initialScope: {
      user: { id: userId, email: userEmail },
    },
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 0.25, // Capture 25% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const rootElement = document.getElementById("root");
if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </React.StrictMode>,
  );
}
