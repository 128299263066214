import { Placement, useFloating, useFocus, useHover, useInteractions } from "@floating-ui/react";
import React, { cloneElement, useState } from "react";

function Popover({
  children,
  placement,
  popoverContent,
}: {
  children: React.ReactElement;
  placement: Placement;
  popoverContent: React.ReactElement;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const { context, floatingStyles, refs } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: setIsOpen,
  });
  const focus = useFocus(context);
  const hover = useHover(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([focus, hover]);

  return (
    <div ref={refs.setReference} {...getReferenceProps()}>
      {cloneElement(children, { ref: refs.setReference })}

      {isOpen && (
        <div
          className="w-fit bg-[#e1e2ec] px-20px py-15px rounded-md flex"
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          {popoverContent}
        </div>
      )}
    </div>
  );
}

export default Popover;
