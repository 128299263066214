import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";

import { IstariLogoColorLarge, LoadingSpinner } from "../../assets/svg";
import { ThemeColors } from "../../themes";
import Button from "../../components/Button";

const FullScreenLoading = () => (
  <div className="w-full h-screen flex items-center justify-center">
    <LoadingSpinner color={ThemeColors.primary[400]} width="50px" height="50px" />
  </div>
);

interface AuthErrorBoundaryProps {
  children: React.ReactNode;
}

function AuthErrorBoundary({ children }: AuthErrorBoundaryProps) {
  const [hasAuthenticationErrors, setHasAuthenticationErrors] = useState(false);

  const auth = useAuth();

  useEffect(() => {
    if (auth && auth.isAuthenticated) {
      window.localStorage.setItem("istari-user", JSON.stringify(auth.user));
    }
    if (auth && auth.error) {
      setHasAuthenticationErrors(true);
    }
  }, [auth, auth.isAuthenticated]);

  switch (auth.activeNavigator) {
    case "signinSilent":
    case "signoutRedirect":
      return <FullScreenLoading />;
    default:
  }

  if (auth.isLoading) {
    return <FullScreenLoading />;
  }

  if (hasAuthenticationErrors) {
    return (
      <div className="flex justify-center text-center bg-[#f3f3f3] w-full h-screen">
        <div className="mt-50px">
          <IstariLogoColorLarge />
          <div className="gap-y-10px justify-center">
            <h4 className="color-gray-800">You are signed out.</h4>
            <span className="subtitle2 color-gray-700">Please click the button to log in again.</span>
          </div>
          <div className="mt-30px">
            <Button fullWidth label="Login" variant="primary" onClick={() => auth.signinRedirect()} />
          </div>
        </div>
      </div>
    );
  }

  return children;
}

export default AuthErrorBoundary;
