import { ModelActionType, UserGrantType, ZitadelRoles } from "../enums";

interface MembershipList {
  roles: string[];
}

interface Membership {
  result?: MembershipList[];
}

export const checkIsCustomerAdmin = (data: Membership | undefined) =>
  data && data.result && data.result[0]?.roles?.includes(ZitadelRoles.ADMIN);

// if multiple owners is allowed, take out the filter below
export const accessOptions = Object.values(UserGrantType)
  .filter((item) => item !== "owner")
  .map((item) => ({
    label: item,
    value: item,
  }));

export const getCanUserRemove = (permission: UserGrantType) => permission === UserGrantType.owner; // only file owner can remove the file

export const getCanUserExtract = (permission: UserGrantType) => permission === UserGrantType.owner; // only model owner can extract the model

export const getCanUserEdit = (permission: UserGrantType) =>
  permission === UserGrantType.editor ||
  permission === UserGrantType.administrator ||
  permission === UserGrantType.owner;

export const getCanUserShare = (permission: UserGrantType) =>
  permission === UserGrantType.editor ||
  permission === UserGrantType.administrator ||
  permission === UserGrantType.owner;

export const getCanUserViewOnly = (permission: UserGrantType) => permission === UserGrantType.viewer; // permission === UserGrantType.approver

export const getCanUser = (permission: UserGrantType) => ({
  [ModelActionType.EXTRACT]: getCanUserExtract(permission),
  [ModelActionType.REMOVE]: getCanUserRemove(permission),
  [ModelActionType.RENAME]: getCanUserEdit(permission),
  [ModelActionType.SHARE]: getCanUserShare(permission),
});
